import React from "react";
import Card from "./card";

const Articles = ({ articles, numPage, maxPage, category }) => {
  const leftArticlesCount = Math.ceil(articles.length / 2);

  // const leftArticles = articles.slice(0, leftArticlesCount);
  // const rightArticles = articles.slice(leftArticlesCount, articles.count);
  // const rightArticles = articles.slice(leftArticlesCount, leftArticlesCount * 2);
  // const middleArticles = articles.slice(leftArticlesCount * 2, articles.length);

  var currentPage = numPage
  var notNull = numPage

  var backButton = numPage
  var nextButton = numPage

  var backLink = ''
  var nextLink = ''

  if (numPage == 2) {
    backButton = category
    backLink = `https://www.logvoid.com/category/${category}`
  } else {
    var page = numPage - 1,
      backButton = `${category}${page}`
    backLink = `https://www.logvoid.com/category/${category}/${page}`
  }

  if (numPage == null) {
    notNull = ''
    currentPage = 1
    nextButton = `${category}2`
    nextLink = `https://www.logvoid.com/category/${category}/2`

  } else {
    var page = numPage + 1,
      nextButton = `${category}${page}`
    nextLink = `https://www.logvoid.com/category/${category}/${page}`
  }



  if (category == "/") {
    return (
      <div>
        <div class="uk-child-width-1@s" data-uk-grid="true">
          <div>
            {articles.map((article, i) => {
              return (
                <Card
                  article={article}
                  key={`article__all__${article.node.slug}`}
                />
              );
            })}
          </div>

          <div>
            <ul class="uk-pagination" data-uk-pagination="{items:100, itemsOnPage:10}"></ul>

            <ul class="uk-pagination uk-flex-center">
              {numPage != null && <li><a href={backButton}>{"<"}</a></li>}

              <li><a href={category}>1</a></li>

              <li class="uk-disabled"><span>...</span></li>

              <li class="uk-active"><a href={category + notNull}>{currentPage}</a></li>

              <li class="uk-disabled"><span>...</span></li>

              <li><a href={category + maxPage}>{maxPage}</a></li>
              {numPage != maxPage && <li><a href={nextButton}>{">"}</a></li>}
            </ul>
          </div>
        </div>
      </div >

    );
  } else {

    return (
      <div>
        <div class="uk-child-width-1@s" data-uk-grid="true">
          <div>
            {articles.map((article, i) => {
              return (
                <Card
                  article={article}
                  key={`article__all__${article.node.slug}`}
                />
              );
            })}
          </div>


          <div>
            <ul class="uk-pagination" data-uk-pagination="{items:100, itemsOnPage:10}"></ul>

            <ul class="uk-pagination uk-flex-center">
              {numPage != null && <li><a href={backLink}>{"<"}</a></li>}

              <li><a href={`https://www.logvoid.com/category/${category}`}>1</a></li>

              {/* <li><a href="https://www.google.com">TOURS,PRICES</a></li> */}

              <li class="uk-disabled"><span>...</span></li>

              <li class="uk-active"><a href={`https://www.logvoid.com/category/${category}/${notNull}`}>{currentPage}</a></li>

              <li class="uk-disabled"><span>...</span></li>

              <li><a href={`https://www.logvoid.com/category/${category}/${maxPage}`}>{maxPage}</a></li>
              {numPage != maxPage && <li><a href={nextLink}>{">"}</a></li>}

            </ul>
          </div>




        </div>
      </div >


    );
  }
  // return (
  //   <div>
  //     <div class="uk-child-width-1@s" data-uk-grid="true">
  //       <div>
  //         <p> {numPage}</p>
  //         <p> {category}</p>

  //         {articles.map((article, i) => {
  //           return (
  //             <Card
  //               article={article}
  //               key={`article__all__${article.node.slug}`}
  //             />
  //           );
  //         })}
  //       </div>




  //       <div>
  //         <ul class="uk-pagination" data-uk-pagination="{items:100, itemsOnPage:10}"></ul>

  //         <ul class="uk-pagination uk-flex-center">
  //           {numPage != null && <li><a href={backButton}>{"<"}</a></li>}

  //           <li><a href={category}>1</a></li>



  //           <li><a href="https://www.google.com">TOURS,PRICES</a></li>




  //           <li class="uk-disabled"><span>...</span></li>

  //           <li class="uk-active"><a href={category + notNull}>{currentPage}</a></li>

  //           <li class="uk-disabled"><span>...</span></li>

  //           <li><a href={category + maxPage}>{maxPage}</a></li>
  //           {/* {numPage != maxPage && <li><a href={category, "/", numPage + 1}>{">"}</a></li>} */}
  //           {numPage != maxPage && <li><a href={nextButton}>{">"}</a></li>}


  //         </ul>
  //       </div>




  //     </div>
  //   </div >


  // );
};

export default Articles;
