import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import Moment from "react-moment";

import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import { render } from "react-dom";

// "gatsby-plugin-offline": "^3.5.0", (also in gatsby-config.js)



const Card = ({ article }) => {

  let progressBarColor = '#fbc630'
  let progressBarSymbol = '❔'
  {
    article.node.percentage <= 20 && <>{progressBarColor = '#46eaee',
      progressBarSymbol = '⛈'}</>
  }
  {
    20 < article.node.percentage <= 40 && <>{progressBarColor = '#37aabd',
      progressBarSymbol = '🌧'}</>
  }
  {
    40 < article.node.percentage <= 60 && <>{progressBarColor = '#3184ab',
      progressBarSymbol = '🌦'}</>
  }
  {
    60 < article.node.percentage <= 80 && <>{progressBarColor = '#3d558d',
      progressBarSymbol = '⛅️'}</>
  }
  {
    article.node.percentage > 80 && <>{progressBarColor = '#3b3667',
      progressBarSymbol = '☀️'}</>
  }



  let companyNames = []
  let companyIcons = []

  let companyLength = article.node.authors.length
  let i = 0
  while (i < companyLength) {
    companyNames.push(article.node.authors[i]["name"]);
    companyIcons.push(
      <Img
        fixed={article.node.authors[i]["picture"].childImageSharp.fixed}
        imgStyle={{ position: "static", borderRadius: "50%" }}
      />
    );
    i++;
  }

  return (
    <Link to={`/article/${article.node.slug}`} className="uk-link-reset">
      <div className="uk-margin-medium uk-card-default uk-card-muted uk-border-rounded">
        <div className="uk-padding-small uk-card-body">
          <div>
            <h2 id="name" className="uk-margin-small-top uk-margin-small-bottom uk-text-left uk-text-bold">
              {article.node.title}
            </h2>

            <p className="uk-background-secondary uk-light">
              <div>
                <p id="description" className="uk-padding-small uk-margin-small-top uk-margin-remove-bottom uk-margin-small-left uk-margin-small-right, uk-text-medium">
                  <code>{article.node.category.name} {">> "}</code>
                  {article.node.description}
                  {/* {"◻︎"} */}
                </p>

                <div class="uk-container uk-container-large uk-margin-small-left uk-padding-small">
                  <Progress percent={article.node.percentage} status="success" theme={{
                    success: {
                      symbol: progressBarSymbol,
                      // '😊',
                      color: progressBarColor
                      // 'rgb(223, 105, 180)'
                    },
                  }} />
                </div>
              </div>
            </p>
          </div>

          <div className="uk-grid-small uk-flex-right" data-uk-grid="true">
            <div>
              <div className="uk-padding-remove uk-margin-small-top uk-margin-small-right uk-text-bold">
                <div>
                  {companyIcons}
                </div>
              </div>

            </div>
          </div>

          <div>
            <p id="time" className="uk-padding-remove uk-text-meta uk-text-right uk-margin-small-right uk-margin-small-bottom">
              <Moment fromNow>{article.node.publishedAt}</Moment>
            </p>
          </div>

        </div>
      </div>
    </Link >
  );
};


export default Card;
